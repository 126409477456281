@font-face {
  font-family: 'LineSeed';
  src: url('./fonts/LINESeedSansTH_Rg.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'LineSeed';
  src: url('./fonts/LINESeedSansTH_Bd.ttf');
  font-weight: bold;
}

@font-face {
  font-family: 'LineSeed';
  src: url('./fonts/LINESeedSansTH_XBd.ttf');
  font-weight: 800;
}

.bg-job {
  background-image: url('./job-bg1.jpg');
  
}

* {
  font-family: 'LineSeed';
}

.bg-ครูเติม-500 {
  background-color: #8b5cf6;
}

.bg-ครูเติม-200 {

  background-color: #ddd6fe;
}

.text-ครูเติม-400 {
  color: #a78bfa;
}


.bg-ครูบิ๊ก-500 {
  background-color: #ec4899;
}

.bg-ครูบิ๊ก-200 {

  background-color: #fbcfe8;
}

.text-ครูบิ๊ก-400 {
  color: #f472b6;
}



.bg-ครูพี-500 {
  background-color: #3b82f6;
}

.bg-ครูพี-200 {

  background-color: #bfdbfe;
}

.text-ครูพี-400 {
  color: #60a5fa;
}

.bg-ครูมีน-500 {
  /* actually 400*/
  background-color: #4ade80;
}

.bg-ครูมีน-200 {
  background-color: #bbf7d0;
}

.text-ครูมีน-400 {
  color: #4ade80;
}









.bg-ครูกิต-500 {
  background-color: #ef4444;
}

.bg-ครูกิต-200 {

  background-color: #fecaca;
}

.text-ครูกิต-400 {
  color: #f87171;
}


.bg-ครูวี-500 {
  background-color: #f59e0b;
}

.bg-ครูวี-200 {

  background-color: #fde68a;
}

.text-ครูวี-400 {
  color: #fbbf24
}


.bg-เวลาว่างครูมีน-500 {
  background-color: #94a3b8;
}

.bg-เวลาว่างครูมีน-200 {
  border: 1px solid #4ade80;
  background-color: #fff;
}

.text-เวลาว่างครูมีน-400 {
  color: #94a3b8;
}


.bg-เวลาว่างครูเติม-500 {
  background-color: #94a3b8;
}

.bg-เวลาว่างครูเติม-200 {
  border: 1px solid #a78bfa;

  background-color: #fff;
}

.text-เวลาว่างครูเติม-400 {
  color: #94a3b8;
}


.bg-เวลาว่างครูบิ๊ก-500 {
  background-color: #94a3b8;
}

.bg-เวลาว่างครูบิ๊ก-200 {
  border: 1px solid #fbcfe8;

  background-color: #fff;
}

.text-เวลาว่างครูบิ๊ก-400 {
  color: #94a3b8;
}


.bg-เวลาว่างครูพี-500 {
  background-color: #94a3b8;
}

.bg-เวลาว่างครูพี-200 {

  border: 1px solid #60a5fa;
  background-color: #fff;
}

.text-เวลาว่างครูพี-400 {
  color: #94a3b8;
}


.bg-เวลาว่างครูวี-500 {
  background-color: #94a3b8;
}

.bg-เวลาว่างครูวี-200 {
  border: 1px solid #fde68a;
  background-color: #fff;
}

.text-เวลาว่างครูวี-400 {
  color: #94a3b8;

}


.bg-เวลาว่างครูกิต-500 {
  background-color: #94a3b8;
}

.bg-เวลาว่างครูกิต-200 {

  border: 1px solid #f87171;
  background-color: #fff;
}

.text-เวลาว่างครูกิต-400 {
  color: #94a3b8;
}


.sc:hover {
  transform: scale(1.05);
}

.notresponsive{
  min-width: 1140px;
  max-height: max-content;
}

